@font-face {
  font-family: 'HK Grotesk';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/hkgrotesk-light-webfont.woff2') format('woff2'),
    url('./fonts/hkgrotesk-light-webfont.woff') format('woff');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/hkgrotesk-regular-webfont.woff2') format('woff2'),
    url('./fonts/hkgrotesk-regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/hkgrotesk-medium-webfont.woff2') format('woff2'),
    url('./fonts/hkgrotesk-medium-webfont.woff') format('woff');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: 600;
  font-style: normal;
  src: url('./fonts/hkgrotesk-semibold-webfont.woff2') format('woff2'),
    url('./fonts/hkgrotesk-semibold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'HK Grotesk';
  font-weight: bold;
  font-style: normal;
  src: url('./fonts/hkgrotesk-bold-webfont.woff2') format('woff2'),
    url('./fonts/hkgrotesk-bold-webfont.woff') format('woff');
}
