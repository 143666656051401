@import './fonts.css';
@import './dropdown.css';
@import './bottom-bar.css';
@import './legend.css';

html,
body {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  font-family: 'HK Grotesk';
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
}

h2 {
  font-size: 1.4em;
}

body {
  background-color: #07092e;
  margin: 0;
  padding: 0;
  color: white;
}

button {
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  text-decoration: none;
  background-color: transparent;
  color: white;
}

button:hover,
button:focus,
button:active {
  outline: none;
  text-decoration: underline;
  cursor: pointer;
}

.annotation text {
  fill: white;
}

.annotation-note-title {
  margin-top: 0.1em;
}

.annotation path {
  stroke: white;
}

/* Need to show scrollability using a gradient or something */
::-webkit-scrollbar {
  display: none;
}

#container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* #container > div,
#container > header {
  border: 1px solid white;
} */

.website-anchor {
  color: white;
  cursor: pointer;
}

header {
  color: white;
  width: 250px;
  align-self: flex-start;
  margin: 1rem;
  background-color: #171d5c;
  margin: 2em;
  padding: 1.5em 2.5em 2em 1.75em;
  min-width: 19em;
  opacity: 0;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 3;
}

#logo {
  margin-bottom: 1.5em;
}

#graph {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -150px;
  overflow: hidden;
  z-index: 2;
}

#dropdown-group {
  z-index: 4;
  display: none;
  padding: 0.5em 1.5em 0.25em;
}

#bottom-bar {
  display: none;
  z-index: 4;
}

#desktop-legend {
  position: relative;
  height: 100%;
  width: 300px;
  padding-left: 1em;
  padding-right: 3em;
  padding-top: 9%;
  padding-bottom: 8%;
}

#back-button {
  position: absolute;
  bottom: 100%;
  left: 0;
  font-size: 1em;
  padding: 0.25em;
}

#back-button:before {
  display: inline-block;
  content: ' ';
  background-image: url('../assets/small-arrow.svg');
  background-size: 0.75em 0.75em;
  height: 0.75em;
  width: 0.75em;
  margin-right: 0.25em;
}

#title {
  font-size: 1.75em;
  margin-bottom: 0;
  margin-top: 0;
  line-height: 1.3;
}

#prompt {
  font-size: 0.85em;
  font-weight: 100;
}

.dropdowns-header {
  font-weight: 300;
  font-size: 0.85em;
  margin-bottom: 0.4em;
}

ul {
  list-style: none;
  position: relative;
  overflow-y: scroll;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 25px;
  padding-left: 0;
}

li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.annotation-group text:not(.annotation-note-title),
.annotation-group rect {
  pointer-events: none;
}

.overflow-gradient {
  position: relative;
  overflow: hidden;
}

.overflow-gradient::before {
  content: '';
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 300px;
  height: 30px;
  background: linear-gradient(#07092e, rgba(7, 9, 46, 0));
}
.overflow-gradient::after {
  content: '';
  position: absolute;
  pointer-events: none;
  bottom: 0;
  width: 300px;
  height: 35px;
  background: linear-gradient(rgba(7, 9, 46, 0), #07092e);
}

.highlighted-year {
  font-size: 1.25em;
  transition: font-size 0.3s;
}

.light-text {
  font-weight: 300;
}

.semi-transparent {
  opacity: 0.4 !important;
  z-index: 1 !important;
}

@media (max-width: 75em) {
  #desktop-legend {
    display: none;
  }

  #dropdown-group {
    display: block;
  }

  #title {
    font-size: 1.2em;
  }

  #bottom-bar {
    height: 2.5rem;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  }

  #container {
    flex-direction: column;
    align-items: stretch;
  }

  #graph {
    margin-left: 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
  }

  header {
    margin: 0;
    padding: 1em 1.5em 0.3em;
    width: 100%;
    font-size: 0.9rem;
  }

  #logo {
    display: none;
  }

  .semi-transparent {
    opacity: 1 !important;
  }
}

@media (max-width: 70em) {
  #graph .year-group {
    font-size: 0.75rem;
  }
}

@media (max-width: 40em) {
  #graph .year-group {
    font-size: 0.5rem;
  }
}

@-moz-document url-prefix() {
  ul:last-child:after {
    content: '';
    display: block;
    width: 2em;
    height: 1.5em;
  }
}
