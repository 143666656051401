.dropdown-container {
  display: inline-block;
  margin-right: 0.25em;
  position: relative;
}

.dropdown-button {
  border-radius: 1em;
  border: 1px solid white;
  font-weight: bold;
  font-size: 1em;
  padding: 0 0.75em 0.25em 0.75em;
}

.dropdown-button:hover,
.dropdown-button:focus,
.dropdown-button:active {
  text-decoration: none;
}

.dropdown-content {
  z-index: 1;
  position: absolute;
  top: calc(100% + 0.5em);
  left: 0;
  border-radius: 0.5em;
  background-color: white;
  max-height: 50vh;
  min-width: 6rem;
  overflow: scroll;
  color: black;
  padding: 0 1em 0 0.9em;
  display: none;
}

.dropdown-content button {
  color: black;
  padding: 0.3em;
  font-size: 0.9em;
  width: 100%;
}

.dropdown-content ul {
  padding-top: 1em;
  padding-bottom: 1em;
}

.dropdown-content button:hover,
.dropdown-content button:focus,
.dropdown-content button:active {
  text-decoration: none !important;
}

.dropdown-content-visible {
  display: block !important;
}

.expanded-dropdown {
  z-index: 5 !important;
}
