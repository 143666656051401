.legend-country {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#desktop-legend button {
  font-size: 1em;
  margin: 0;
  padding: 0;
  text-align: start;
  text-decoration: underline !important;
}

#desktop-legend button span {
  text-align: start;
}

#desktop-legend .bold {
  font-weight: 600;
}
