#bottom-bar * {
  color: white;
}

#bottom-bar-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

#bottom-bar-expandable-container {
  position: absolute;
  bottom: -30;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: black;
}

#bottom-bar-expandable-content {
  position: relative;
  padding: 1em 1.5em;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 0;
  transition: max-height 0.2s;
}

#bottom-bar-expandable-content button {
  font-size: 1em;
  margin: 0;
  padding: 0;
  text-align: start;
  text-decoration: underline !important;
  display: flex;
  align-items: flex-start;
}

.content-expanded {
  bottom: 0 !important;
}

.content-expanded #bottom-bar-expandable-content {
  max-height: calc(100vh - 9rem) !important;
}

#bottom-bar-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 3;
  height: 2.5rem;
}

#bottom-bar-button:hover,
#bottom-bar-button:focus,
#bottom-bar-button:active {
  text-decoration: none;
}

#bottom-bar-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 99%;
  width: 100%;
  height: 30px;
  background: linear-gradient(black, transparent);
}

#bottom-bar-expandable-container::after {
  content: '';
  position: absolute;
  pointer-events: none;
  bottom: -1;
  width: 100%;
  height: 35px;
  background: linear-gradient(transparent, black);
}
